import { useSnackbar, SnackbarKey, SnackbarProvider, SnackbarMessage, VariantType } from "notistack";
import { Notification } from "./state";

// UTILITIES TO CREATE SNACKBAR
export const createNotification = (message: SnackbarMessage, variant: VariantType, key?: SnackbarKey): Notification => {
    const k = key ?? new Date().getTime() + Math.random();
    return {
        key: k,
        message,
        options: {
            variant
        }
    };
};

export const createFailureNotification = (): Notification =>
    createNotification("Houston, wir haben ein Problem! Probieren Sie es später nochmal.", "warning");
