import { ReactNode } from "react";
import { match } from "ts-pattern";

export type Loaded<T> = { type: "loaded"; value: T };
export type Loading = { type: "loading" };
export type Uninitialized = { type: "uninitialized" };
export type Failure<E> = { type: "error"; value: E };

/**
 * A primitive type that represents a loadable resource
 **/
export type Loadable<T, E extends Error = Error> = Loaded<T> | Loading | Failure<E> | Uninitialized;

export const loadable = {
    load: { type: "loading" as const },
    uninitialized: { type: "uninitialized" as const },
    failed: <E extends Error = Error>(e: E) => ({ type: "error" as const, value: e }),
    loaded: <T>(t: T) => ({ type: "loaded" as const, value: t }),

    isLoaded: <T>(l: Loadable<T>): l is Loaded<T> => {
        return l?.type === "loaded";
    },
    isUninit: (l: Loadable<unknown>): l is Uninitialized | undefined => l === undefined || l?.type === "uninitialized",

    wrap: async <T>(cb: () => PromiseLike<T> | T): Promise<Loadable<T>> => {
        try {
            return { type: "loaded", value: await cb() };
        } catch (err) {
            return { type: "error", value: err };
        }
    },

    map<T extends any, U extends any>(l: Loadable<T>, cb: (t: T) => U): Loadable<U> {
        if (l.type === "loaded") {
            return this.loaded(cb(l.value));
        }
        return l;
    },

    flatMap<T extends any, U extends any>(l: Loadable<T>, cb: (t: T) => Loadable<U>): Loadable<U> {
        if (l.type === "loaded") {
            return cb(l.value);
        }
        return l;
    },

    fromMaybeNone<T, E extends Error>(m: T | undefined, e: E): Loadable<T, E> {
        if (m === undefined) {
            return { type: "error", value: e };
        }
        return this.loaded(m);
    },

    match: <T, R = ReactNode, E extends Error = Error>(
        l: Loadable<T, E> | undefined,
        cb: {
            loaded: (value: T) => R;
            uninitialized: () => R;
            loading: () => R;
            failure: (value: E) => R;
        }
    ) =>
        match(l ?? { type: "uninitialized" as const })
            .with({ type: "loaded" }, ({ value }) => cb.loaded(value))
            .with({ type: "loading" }, cb.loading)
            .with({ type: "error" }, ({ value }) => cb.failure(value))
            .with({ type: "uninitialized" }, cb.uninitialized)
            .exhaustive()
};
