import { AnyAction, configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { ThunkMiddlewareFor } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import { initialSnackbarState, snackbarReducer, SnackbarState } from "./snackbar";

import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

export * from "./snackbar";
export * from "./utils";

export interface SharedReduxState {
    snackbar: SnackbarState;
}

export const initialSharedState = {
    snackbar: initialSnackbarState
};

export const sharedReducer = {
    snackbar: snackbarReducer
};

// NOTE: can be used to construct a store, that just contains the shared slices, but is generally here to do the type inference
// const configureSharedStore = () => configureStore<SharedReduxState>({ preloadedState: initialSharedState, reducer: sharedReducer });
export type SharedStore = EnhancedStore<SharedReduxState, AnyAction, any>;

export const useSharedDispatch = useDispatch as () => SharedStore["dispatch"];
export const useSharedSelector: TypedUseSelectorHook<SharedReduxState> = useSelector;
