import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { SnackbarKey } from "notistack";
import { Notification, initialSnackbarState } from "./state";

export const snackbarSlice = createSlice({
    name: "snackbar",
    initialState: initialSnackbarState,
    reducers: {
        enqueueSnackbar: (state, { payload }: PayloadAction<Notification>) => {
            state.notifications.push(payload as Draft<Notification>);
        },
        closeSnackbar: (state, { payload }: PayloadAction<SnackbarKey | undefined>) => {
            const dismissAll = payload === undefined;
            state.notifications = state.notifications.map(notification => {
                const shouldDismiss = dismissAll || notification.key === payload;
                return shouldDismiss ? { ...notification, dismissed: true } : { ...notification };
            });
        },
        removeSnackbar: (state, { payload: key }: PayloadAction<SnackbarKey>) => {
            state.notifications = state.notifications.filter(notification => notification.key !== key);
        }
    }
});

export const { enqueueSnackbar, closeSnackbar, removeSnackbar } = snackbarSlice.actions;
export const { reducer: snackbarReducer } = snackbarSlice;

export * from "./state";
export * from "./utils";
export * from "./snacker";
