const create = (status: number, message?: string, config: { snackbar: boolean } = { snackbar: true }) => ({
    status,
    message,
    config
});

const fallback = "Houston, wir haben ein Problem! Probieren Sie es später nochmal.";
export const OnpreoErrors = {
    ok: create(200),
    "invalid-body": create(422, fallback),
    "invalid-multipart": create(422, fallback),
    "invalid-headers": create(422, fallback),
    "invalid-query": create(422, fallback),
    unauthenticated: create(401, "Nutzer ist nicht authentifiziert", { snackbar: false }),
    "wrong-token": create(401, "Sie haben nicht die nötigen Rechte, um diese Ressource zu laden"),
    "wrong-password": create(401, "Das angegebene Passwort ist falsch"),
    "database-connection-failed": create(500, "Probleme bei der Datensynchronisation"),
    "database-save-failed": create(500, "Probleme bei der Datensynchronisation"),
    "database-load-failed": create(500, "Probleme bei der Datensynchronisation"),
    "database-creation-failed": create(500, "Probleme bei der Datensynchronisation"),
    "database-search-failed": create(500, "Probleme bei der Datensynchronisation"),
    "database-update-failed": create(500, "Probleme bei der Datensynchronisation"),
    "database-delete-failed": create(500, "Probleme bei der Datensynchronisation"),
    "mailjet-error": create(500, fallback),
    "hubspot-error": create(500, fallback),
    "s3-presigned-error": create(500, fallback),
    "s3-copy-error": create(500, fallback),
    "s3-file-missing": create(500, fallback),
    "s3-buffer-upload-failed": create(500, fallback),
    "s3-delete-failed": create(500, fallback),
    "s3-check-exists-failed": create(500, fallback),
    "prospective-buyer-failed": create(500, fallback),
    "user-missing": create(404, "Nutzer wurde nicht gefunden"),
    "user-already-existing": create(400, "Ein Nutzer mit dieser Email existiert bereits"),
    "team-user-existing": create(409, "Ein Nutzer mit dieser Email existiert bereits"),
    "team-user-missing": create(404, "Nutzer wurde nicht gefunden"),
    "user-creation-failed": create(500, "Der Nutzer konnte nicht angelegt werden"),
    "real-estate-missing": create(404, "Immobilie konnte nicht gefunden werden"),
    "real-estate-not-owned": create(401, "Immobilie konnte nicht geladen werden"),
    "workspace-missing": create(404, "Das Team konnte nicht geladen werden"),
    "notes-missing": create(404, "Notizen konnten nicht gefunden werden"),
    "tasks-missing": create(404, "Aufgaben konnten nicht gefunden werden"),
    "appointments-missing": create(404, "Aufgaben konnten nicht gefunden werden"),
    "pipeline-missing": create(404, "Pipeline konnten nicht gefunden werden"),
    "location-missing": create(404, "Standort konnte nicht gefunden werden"),
    "contact-missing": create(404, "Der Kontakt konnte nicht gefunden werden"),
    "contact-not-owned": create(401, "Der Kontakt konnte nicht geladen werden"),
    "price-assistants-missing": create(404, "Preisassistenten konnten nicht gefunden werden"),
    "integration-iw-ftp-error": create(500, "Probleme bei der Datensynchronisation"),
    "integration-iw-conversion-failed": create(500, "Immobilie konnte nicht in das OpenImmo Format konvertiert werden"),
    "integration-is-auth-failed": create(500, "ImmoScout Verbindung konnte nicht erstellt werden"),
    "integration-is-auth-callback-failed": create(500, "ImmoScout Verbindung konnte nicht erstellt werden"),
    "integration-is-connection-missing": create(401, "Das Team besitzt keine aktive ImmoScout anbindung"),
    "integration-is-no-expose": create(404, "Immobilie besitzt noch kein ImmoScout Exposé"),
    "integration-is-conversion-failed": create(500, "Immobilie konnte nicht in das von ImmoScout verwendete Format konvertiert werden"),
    "integration-is-fetch-failed": create(500, "Immobilie konnte nicht an ImmoScout übermittelt werden"),
    "integration-is-invalid-response": create(500, "Immobilie konnte nicht an ImmoScout übermittelt werden"),
    "integration-is-no-publication": create(500, "Immobilie ist noch nicht auf ImmoScout veröffentlicht"),
    "integration-sprengnetter-failed": create(500, "Automatisierte Bewertung konnte nicht ermittelt werden"),
    "integration-csv-export-failed": create(500, "CSV Export konnte nicht erstellt werden"),
    "form-not-created": create(500, "Formulardesign konnte nicht erstellt werden"),
    "design-not-created": create(500, "Formular konnte nicht erstellt werden"),
    "customer-not-created": create(500, "onpreo Kunde konnte nicht erstellt werden"),
    "convert-to-proper-format-failed": create(500, "Konvertieren in das richtige Format fehlgeschlagen"),
    "receive-sprengnetter-valuation-failed": create(500, "Sprengnetter-Bewertung konnte nicht empfangen werden"),
    "mail-fetch-failed": create(500, "E-Mails konnten nicht geladen werden"),
    "mail-imap-client-failed": create(500, "Es gab Probleme bei der Kommunikation mit dem E-Mail Server"),
    "mail-client-imap-connect-failed": create(500, "Es konnte keine Verbindung zum E-Mail Server aufgebaut werden"),
    "mail-client-smtp-connect-failed": create(500, "Es konnte keine Verbindung zum E-Mail Server aufgebaut werden"),
    "mail-send-failed": create(500, "Die E-Mail konnte nicht versendet werden"),
    "mail-send-not-found": create(500, "Die E-Mail konnte nach dem Versand nicht gefunden werden"),
    "mail-mailbox-missing": create(404, "Das Postfach konnte nicht gefunden werden"),
    "mail-secret-malformed": create(500, "Die E-Mail Einstellungen sind ungültig"),
    "mail-delete-uuid-unknown": create(500, "Die E-Mail Einstellungen sind ungültig", { snackbar: false }),
    "email-engine": create(500, "Es gab Probleme bei der Kommunikation mit dem E-Mail Server"),
    "email-engine-oauth": create(500, "Es gab Probleme bei der Kommunikation mit dem E-Mail Server"),
    "email-engine-invalid": create(403, "Die E-Mail Einstellungen sind ungültig", { snackbar: false }),
    "secret-api-call-failed": create(500, fallback),
    "secret-no-secret": create(404, "Es konnten keine E-Mail Einstellungen gefunden werden"),
    "secret-failed-to-get-secret": create(401, "Es konnten keine E-Mail Einstellungen gefunden werden", { snackbar: false }),
    "secret-validate": create(404, "Die E-Mail Einstellungen konnten nicht bestätigt werden"),
    "server-pdf-generation-error": create(500, "Ihr Dokument konnte nicht erstellt werden"),
    "activities-missing": create(404, "Aktivitäten konnten nicht gefunden werden"),
    "open-ai-server-overload": create(400, "Es besteht eine hohe Nachfrage nach Texten. Versuchen Sie es in ein paar Minuten nochmal."),
    // stripe specific error codes
    "customer-id-missing": create(500, "Uns fehlen Zahlungsinformationen von Ihnen. Bitte wenden Sie sich an unseren Support."),
    "payment-method-missing": create(500, "Sie haben keine Zahlungsmethode angegeben. Bitte wenden Sie sich an unseren Support."),
    "subscription-not-created": create(500, "Ihre Lizenz konnte nicht angelegt werden. Bitte wenden Sie sich an unseren Support."),
    "subscription-id-missing": create(500, "Sie haben keine Lizenz erworben. Bitte wenden Sie sich an unseren Support."),
    "unauthorized-subscription": create(500, "Diese Feature ist nicht in Ihrer Lizenz enthalten. Führen Sie ein upgrade durch."),
    "team-user-limit-reached": create(500, "Sie haben die maximale Anzahl Nutzer für Ihren Account erreicht"),
    "buyer-preference-missing": create(404, "Käuferpräferenzen konnten nicht gefunden werden"),
    "twilio-invalid-phone": create(400, "Ungültige Telefonnummer"),
    "imv-id-missing": create(400, "IMV-ID fehlt im Betreff der E-Mail"),
    // Automation Engine stuff
    "auto-mq-invalid-trigger": create(500, "Ungültiger Trigger Typ"),
    "auto-mq-invalid-trigger-campaign": create(404, "Keine Trigger Kampagne vorhanden"),
    "auto-mq-invalid-trigger-early-opt-out": create(409, "Early Opt-out für Trigger"),
    "auto-mq-invalid-context": create(500, "Ungültiger Kontext"),
    "auto-mq-invalid-node": create(500, "Ungültige Aktion"),
    "auto-mq-db-error": create(500, "Datenbankfehler"),
    "auto-mq-create-campaign-error": create(500, "Kampagne konnte nicht erstellt werden"),
    "auto-mq-get-campaign-error": create(500, "Kampagne konnten nicht geladen werden"),
    "auto-mq-call-trigger-error": create(500, "Der Trigger konnte nicht aufgerufen werden"),
    "auto-mq-double-opt-in-error": create(500, "Das Senden der Double-Opt-In-Anfrage ist fehlgeschlagen"),
    "price-hubble-id-missing": create(400, "PriceHubble-ID fehlt in der E-Mail Adresse"),
    "invalid-activity-type": create(422, fallback),
    // bla bla bla
    // error code for unknown errors (catch all kinda stuff),
    unknown: create(500, fallback)
} as const;

export type ErrorCode = keyof typeof OnpreoErrors;
export const onpreoErrors = Object.keys(OnpreoErrors) as ErrorCode[];

export const isOnpreoError = (value: string): value is ErrorCode => {
    return onpreoErrors.includes(value as ErrorCode);
};
