import { SnackbarMessage, OptionsObject, SnackbarKey } from "notistack";

export type Notification = {
    key: SnackbarKey;
    message: SnackbarMessage;
    options?: OptionsObject;
    dismissed?: boolean;
};

export type SnackbarState = {
    notifications: Notification[];
};

export const initialSnackbarState: SnackbarState = {
    notifications: []
};
