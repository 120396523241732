import { useSnackbar, SnackbarKey, SnackbarProvider, SnackbarMessage, VariantType } from "notistack";
import { FC, useEffect, useRef } from "react";
import { Slide, makeStyles } from "@material-ui/core";
import { removeSnackbar, useSharedDispatch, useSharedSelector } from "..";

/**
 * Needs to be used in a children of the SnackbarProvider component, links
 * the redux state to the notistack state
 */
export const useNotifier = () => {
    const dispatch = useSharedDispatch();
    const notifications = useSharedSelector(store => store.snackbar.notifications || []);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const displayed = useRef<SnackbarKey[]>([]);

    const storeDisplayed = (id: SnackbarKey) => {
        displayed.current = [...displayed.current, id];
    };

    const removeDisplayed = (id: SnackbarKey) => {
        displayed.current = displayed.current.filter(key => id !== key);
    };

    useEffect(() => {
        notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.current.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                onExited: (event, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeSnackbar(myKey));
                    removeDisplayed(myKey);
                }
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

const Notifier = () => {
    useNotifier();
    return null;
};

const useStyles = makeStyles(theme => ({
    snackbarRoot: {
        fontSize: "0.9em",
        padding: theme.spacing(0.5, 0)
    }
}));

/**
 * Mounts the SnackbarProvider and calls useNotifier to link
 * to redux storage
 *
 * NOTE: Must be a children of the redux store provider
 */
export const Snacker: FC = ({ children }) => {
    const classes = useStyles();
    return (
        <SnackbarProvider
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
            classes={{
                // @ts-ignore: this class exists but is not in the types
                message: classes.snackbarRoot
            }}
            TransitionComponent={Slide}
        >
            <Notifier />
            {children}
        </SnackbarProvider>
    );
};
