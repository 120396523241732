import { RealEstate } from "@onpreo/database";

export type RealEstateState = RealEstate;

export const initialRealEstateState = {
    salesProgress: "AGREE_TO_START_PRICE",
    category: "ETW",
    address: {
        nation: "",
        street: "",
        house_number: "",
        zip: "",
        town: ""
    },
    numberOfRequests: 0,
    askingPrice: {},
    workspace: {},
    user: {}
} as RealEstateState;
