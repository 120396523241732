import { initialRealEstateState, RealEstateState } from "./real-estate.state";
import { initialSharedState, SharedReduxState } from "@onpreo/slices";

export default interface ReduxState extends SharedReduxState {
    realEstate: RealEstateState;
}

export const initialReduxState: ReduxState = {
    realEstate: initialRealEstateState,
    ...initialSharedState
};
