// Utility methods for clients
import { createFailureNotification, createNotification, enqueueSnackbar, SharedStore } from "@onpreo/slices";
import axios from "axios";
import { define, is, type, validate } from "superstruct";
import { ErrorCode, isOnpreoError, OnpreoErrors } from "./codes";

let store: SharedStore;
export const injectStore = (_store: SharedStore) => {
    store = _store;
};

// dead simple validation schema for expected api error
const OnpreoError = define<ErrorCode>("onpreo-error", isOnpreoError);
const ApiResponse = type({
    code: OnpreoError
});

/**
 * The axios instance, that handles connections to one of the next instances
 *
 * The `server` will catch all errors and return undefined in cases where the service failed
 *
 * SAFETY:
 * - The URLs are expected to point to a server that uses the upsy-daisy api()-api
 **/
export const onpreoClient = axios.create();
// NOTE: This is what makes this axios instance special
export const serverInterceptor = onpreoClient.interceptors.response.use(
    // onFulfilled
    undefined,
    // onRejected
    error => {
        if (axios.isAxiosError(error)) {
            const data = error.response?.data;
            // NOTE: "Unauthenticated" needs to be handled on a per app basis (eg. redirect or re-authenticate)
            if (is(data, ApiResponse)) {
                // through the `code` we can create a specialized failure notification
                const { message, config } = OnpreoErrors[data.code];
                if (config.snackbar)
                    // and dispatch that to the user
                    store.dispatch(enqueueSnackbar(createNotification(message, "error")));

                return Promise.reject(error);
            }
        }
        // this means, we don't know what the response contains
        // since this is the client side, the error should have been registered by sentry server side already
        // so just do nothing
        store.dispatch(enqueueSnackbar(createFailureNotification()));
        // This is actually a big decision, what should be returned here
        return Promise.reject(error);
    }
);
